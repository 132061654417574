import React, { useEffect } from "react";
import { Link, graphql } from "gatsby";
import BEMHelper from "react-bem-helper";
import Helmet from "react-helmet";

import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Layout from "../components/layout";
import SEO from "../components/seo";
import HeaderSolidColor from "../modules/headers/header-solid-color";

import moduleConductor from "../modules/module-conductor";
import ModuleCarousel from "../modules/module-carousel";

import { richTextConfig } from "../rich-text-config/rich-text-config";

import articleFallbackImg from "../assets/img/patterns/article-fallback.png";

const bem = new BEMHelper({
  name: "template-contact-thanks",
});

const ContactThanks = ({ data, location, pageContext }) => {
  const page = data.contentfulTemplateContactPage;

  useEffect(() => {
    // document.getElementById("loader_wrap").classList.add("d-none");
    // document.getElementById("loader_wrap").classList.add("loaded");
  }, []);

  const Loader = () => {
    let loaderDiv = document.getElementById("loader");
    let delay = 0.004;

    for (let x = 0; x < 40; x++) {
      let width = x * 2.5 + "%";
      let div = document.createElement("div");
      loaderDiv.appendChild(div);
      div.style.left = width;
      div.style.top = 0;
      div.style.animationDelay = ((x - 20) * (x - 20) * delay) / 8 + "s";
    }
  };

  const metaImage = page.seoShareImage ? page.seoShareImage.file.url : null;
  const metaTitle = page?.seoPageTitle || page?.pageTitle;
  return (
    <Layout location={location}>
      <SEO
        title={metaTitle}
        description={
          page.pageDescription?.pageDescription ||
          "Let's connect! Learn more about how UnitedLex can help solve your legal and business challenges or sign up to receive our thought leadership today."
        }
        image={metaImage}
        url={location?.href}
      />
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>

      <HeaderSolidColor
        headerTitle={page?.pageTitle || null}
        headerDescription={page?.pageDescription?.pageDescription || null}
      />
      <div {...bem()}>
        <div {...bem("main-content-wrapper")}>
          <article {...bem("body")}>
            <div {...bem("body-copy-wrapper")}>
              {page.contactPageForm &&
              page.contactPageForm.successMessage &&
              documentToReactComponents(
                JSON.parse(page.contactPageForm.successMessage.raw),
                richTextConfig()
              )}
            </div>
          </article>
          <aside {...bem("aside")}>
            {page.sideBox1 && (
              <div {...bem("side-box")}>
                <span {...bem("visual-hairline")} />
                <div {...bem("side-box-copy")}>
                  {documentToReactComponents(
                    JSON.parse(page.sideBox1.raw),
                    richTextConfig()
                  )}
                </div>
              </div>
            )}

            {page.sideBox2 && (
              <div {...bem("side-box")}>
                <span {...bem("visual-hairline")} />
                <div {...bem("side-box-copy")}>
                  {documentToReactComponents(
                    JSON.parse(page.sideBox2.raw),
                    richTextConfig()
                  )}
                </div>
              </div>
            )}
          </aside>
        </div>
      </div>
    </Layout>
  );
};

export default ContactThanks;

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulTemplateContactPage(slug: { eq: $slug }) {
      pageTitle
      doNotIndex
      pageDescription {
        pageDescription
      }
      seoPageTitle
      seoShareImage {
        ...ContentfulAssetFragment
      }
      slug
      contactPageForm {
        contentful_id
        formId
        formHeader {
          raw
        }
        successMessage {
          raw
        }
      }
      sideBox1 {
        raw
      }
      sideBox2 {
        raw
      }
    }
  }
`;
